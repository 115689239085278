import parse from 'html-react-parser'

import { LegalRequirementResponse, LegalRequirementType } from 'mmfintech-commons-types'
import { isOwnerOrAdministrator } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import LegalIcon from '@images/icons/legal.svg?react'
import Warning from '@images/icons/warning-blue.svg?react'

import { LegalRequirementsWrapper } from './LegalRequirements.styled'

const links = {
  GENERAL_TERMS: 'https://www.jetonbank.com/general-terms',
  PRICING_POLICY: '/files/Jetonbank_Pricing_Policy.pdf',
  TERMS_OF_USE: 'https://www.jetonbank.com/terms-conditions'
}

interface LegalRequirementsModal {
  customerRole: any
  requirements: LegalRequirementResponse[]
}

export const LegalRequirements = ({ customerRole, requirements }: LegalRequirementsModal) => {
  //todo not localized

  const prepareLink = (type: LegalRequirementType) =>
    `<a href="${links[type]}" target="_blank" rel='noreferrer'>${tr(
      `MERCHANTS.LEGAL_REQUIREMENTS.${type}`,
      type.replace(/_/g, ' ')
    )}</a>`

  const prepareLinks = () => {
    if (requirements.length > 1) {
      const list = requirements.map(entry => prepareLink(entry.type))
      const last = list.pop()
      return `${list.join(', ')} and ${last}`
    }

    return prepareLink(requirements[0].type)
  }

  if (isOwnerOrAdministrator(customerRole)) {
    return (
      <LegalRequirementsWrapper>
        <h3>{tr('PORTAL.LEGAL.NOTICE', 'Important notice')}</h3>

        <div className='icon'>
          <LegalIcon />
        </div>

        <div className='content'>
          <p>
            We have updated our {parse(prepareLinks())} for Jeton Bank Services. Before you continue using your account,
            please take a moment to review the new terms.
            {/* fix */}
            {/* {tr(
              'PORTAL.LEGAL.OWNER_LINE1',
              `We have updated our {{DOCUMENTS}} for Jetonbank services. Before you continue using your account, please take a moment to review the new terms.`
            ).replace('{{DOCUMENTS}}', parse(prepareLinks()) as string)} */}
          </p>

          <p>
            {tr(
              'PORTAL.LEGAL.OWNER_LINE2',
              'By clicking "Accept" you confirm that you have read and agreed to these terms.'
            )}
          </p>
          <div className='warning'>
            <Warning />
            <p>
              {tr(
                'PORTAL.LEGAL.OWNER_LINE3',
                'Please Note: If you do not accept on or before February 28, 2025, we will consider it as your acceptance of the uploaded terms.'
              )}
            </p>
          </div>
        </div>
      </LegalRequirementsWrapper>
    )
  }

  return (
    <LegalRequirementsWrapper>
      <h3>{tr('PORTAL.LEGAL.NOTICE', 'Important notice')}</h3>

      <div className='icon'>
        <LegalIcon />
      </div>

      <div className='content'>
        <p>
          Please be advised that the account owner or administrator has the authority to review and accept the updated{' '}
          {parse(prepareLinks())} for JetonBank services.
          {/* fix */}
          {/* {tr(
            'PORTAL.LEGAL.STAFF_LINE1',
            `Please be advised that the account owner or administrator has the authority to review and accept the updated {{DOCUMENTS}} for JetonBank services.`
          ).replace('{{DOCUMENTS}}', parse(prepareLinks()) as string)} */}
        </p>
        <p>
          {tr(
            'PORTAL.LEGAL.STAFF_LINE2',
            'If you are not the owner or administrator of this account, please ensure that the authorized person (owner or administrator) logs into their account and reviews the new terms. The acceptance of these terms is required for the continued use of Jeton Bank services.'
          )}
        </p>
      </div>
    </LegalRequirementsWrapper>
  )
}
