import React, { useContext } from 'react'

import { useGetConversationThreadsQuery } from 'mmfintech-backend-api'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import ConversationModal from './ConversationModal'
import { ConversationsBannerWrapper } from './styled/conversationBanner.styled'

import { ConversationThread } from 'mmfintech-commons-types'

import ArrowIcon from '../../../images/icons/arrow-outlined.svg?react'
import ErrorIcon from '../../../images/icons/error-triangle.svg?react'

const ConversationsBanner: React.FC = () => {
  const { data: conversationThreads } = useGetConversationThreadsQuery()
  const filteredConversations =
    isValidArray(conversationThreads) &&
    conversationThreads.filter((conversation: ConversationThread) => conversation?.status === 'WAITING_CLIENT')

  const { modalShow }: any = useContext(GlobalContext)

  const handleOpenThread = (threadId: number, type: string) => {
    modalShow({
      options: { closeOnClickOutside: true, size: 'auto', overflow: 'auto' },
      content: <ConversationModal threadId={threadId} type={type} />
    })
  }

  return isValidArray(filteredConversations) ? (
    <>
      {filteredConversations.map(({ id, type }: { id: number; type: string }) => (
        <ConversationsBannerWrapper key={id} onClick={() => handleOpenThread(id, type)}>
          <div className='badge-container'>
            <div className='badge'>
              <ErrorIcon />
              {tr('FRONTEND.CONVERSATION.BANNER.TITLE', 'Action Required')}
            </div>
            <div className='ml-2'>
              <ArrowIcon />
            </div>
          </div>
          <div className='text'>Start chat to resolve now</div>
        </ConversationsBannerWrapper>
      ))}
    </>
  ) : null
}

export default ConversationsBanner
