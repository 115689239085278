import { useContext } from 'react'

import { GlobalContext, formatDate, formatFloat, formatMoney, isValidArray, tr, usePagination } from 'mmfintech-commons'
import { Button, DataTable, NoElementsMessage, Pagination, Spinner } from 'mmfintech-portal-commons'
import { stakingPeriods, stakingStatuses, useCurrencies, useLazyGetInvestmentsQuery } from 'mmfintech-backend-api'

import WithdrawModal from './WithdrawModal'
import { InvestmentsTableCurrentContainer } from './styled/investmentsTableCurrent.styled'
import { breakpoints } from '../../constants'
import { CurrencyIcon } from '../../components'

const InvestmentsTableCurrent = () => {
  const { modalShow } = useContext(GlobalContext)

  const { getCurrencyPrecision } = useCurrencies()

  const [fetchInvestments, { data: activeInvestments, isFetching }] = useLazyGetInvestmentsQuery()

  const handleWithdraw = (transactionId: number) => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: true, closeOnEscape: true },
      content: <WithdrawModal transactionId={transactionId} />
    })
  }

  const pagination = usePagination({
    rowsPerPage: 6,
    reload: async (params: any, onSuccess?: (response: any) => void) => {
      const filter = {
        ...params,
        statuses: ['ACTIVE', 'PENDING_CANCELLATION'],
        sort: 'id-desc'
      }
      try {
        const response = await fetchInvestments(filter).unwrap()
        typeof onSuccess === 'function' && onSuccess(response)
      } catch {}
    }
  })

  return (
    <InvestmentsTableCurrentContainer>
      {isFetching ? (
        <Spinner />
      ) : isValidArray(activeInvestments?.content) ? (
        <>
          <DataTable.Table scale2={`${breakpoints.xl}px`} skipHover>
            <DataTable.Head>
              <DataTable.Row>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.CURRENCY', 'Currency')}</DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.AMOUNT', 'Amount')}</DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.START_DATE', 'Start Date')}
                </DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.END_DATE', 'End Date')}</DataTable.HeadCell>
                <DataTable.HeadCell>{tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.PERIOD', 'Period')}</DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.INTEREST_APY', 'Interest APY')}
                </DataTable.HeadCell>
                <DataTable.HeadCell>
                  {tr('FRONTEND.SAVINGS.COLUMNS.CURRENT.ESTIMATE_EARNINGS', 'Estimate Earnings')}
                </DataTable.HeadCell>
              </DataTable.Row>
            </DataTable.Head>

            <DataTable.Body>
              {activeInvestments?.content.map(staking => {
                const { endDate, reward, startDate, currency, duration, amountStaked, status, amountDue, id } = staking
                const currencyDecimals = getCurrencyPrecision(currency)

                return (
                  <DataTable.Row data-test='table-row' key={id}>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.CURRENCY_KEY', 'Currency')}
                      className='bold'
                      data-test='current-stakings-asset'>
                      <div className='currency-wrapper'>
                        <CurrencyIcon currency={currency} size='3rem' circle />
                        {currency}
                      </div>
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.AMOUNT_KEY', 'Amount')}
                      data-test='current-stakings-staked-amount'>
                      {formatMoney(amountStaked, currency, currencyDecimals)}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.START_DATE_KEY', 'Start Date')}
                      data-test='current-stakings-start-date'>
                      {formatDate(startDate)}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.END_DATE_KEY', 'End Date')}
                      data-test='current-stakings-end-date'>
                      {formatDate(endDate)}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.PERIOD_KEY', 'Period')}
                      className='bold'
                      data-test='current-stakings-period'>
                      {`${stakingPeriods[duration]} days`}
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.APY_KEY', 'Est APY*')}
                      className='alt-color'
                      data-test='current-stakings-apy'>
                      <span className='stakings-apy'>{formatFloat(reward, 2) || 0} %</span>
                    </DataTable.Cell>
                    <DataTable.Cell
                      caption={tr('FRONTEND.SAVINGS.TABLE.CURRENT.EST_EARNINGS_KEY', 'Est Earnings')}
                      className='bold'
                      data-test='current-stakings-est-earnings'>
                      {formatFloat(amountDue - amountStaked, currencyDecimals, currencyDecimals > 1 ? 2 : 0)}
                    </DataTable.Cell>

                    <DataTable.Cell className='button-wrapper'>
                      {status === stakingStatuses.ACTIVE && (
                        <Button
                          className='withdraw-button'
                          color='round-secondary'
                          text={tr('FRONTEND.SAVINGS.TABLE.CURRENT.BUTTON_WITHDRAW', 'Withdraw')}
                          onClick={() => handleWithdraw(id)}
                          data-test='withdraw-button'
                        />
                      )}
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              })}
            </DataTable.Body>
          </DataTable.Table>
          {activeInvestments && activeInvestments.totalPages > 1 && <Pagination {...pagination.register()} />}
        </>
      ) : (
        <NoElementsMessage
          message={tr('FRONTEND.SAVINGS.TABLE.CURRENT.NO_ACTIVE_INVESTMENTS', "You don't have any active investments")}
        />
      )}
    </InvestmentsTableCurrentContainer>
  )
}

export default InvestmentsTableCurrent
