import styled from 'styled-components'

export const CardBrandButtonStyled = styled.div`
  flex: 1;
  height: 3.2rem;
  cursor: pointer;
  max-width: 14.5rem;

  background-color: #6bcdfd33;
  border: 1px solid #6bcdfd33;
  border-radius: 0.8rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 5px 4px;

  font-size: 1.2rem;
  font-weight: 400;

  &:hover {
    border: 1px solid #6bcdfd;
  }

  &.selected {
    background-color: white;
    border: 1px solid #6bcdfd;
  }
`

export const CardBrandWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`
