import styled from 'styled-components'

import { formatMoney, isValidArray, tr, usePagination } from 'mmfintech-commons'
import { getOnboardingStatusName, useLazyGetPaymentProgramStatisticsQuery } from 'mmfintech-backend-api'
import { DataTable, ErrorDisplay, NoElementsMessage, Pagination, Preloader } from 'mmfintech-portal-commons'

import { tablePadding } from '../../components/styled/table.styled'
import { breakpoints } from '../../constants'
import StatusBanner from '../../components/StatusBanner'

import NotPaidIcon from '../../images/icons/application-fee-not-paid.svg?react'
import PaidIcon from '../../images/icons/application-fee-paid.svg?react'

function ReferralStatistics({ currencyCode }) {
  const [fetchStatistics, { data: statistics, isLoading, error }] = useLazyGetPaymentProgramStatisticsQuery()

  const pagination = usePagination({
    rowsPerPage: 5,
    reload: async (params: any, onSuccess: (response: any) => void) => {
      if (params.page === 0 || params.page !== pagination.currentPage) {
        try {
          const response = await fetchStatistics(params).unwrap()
          onSuccess(response)
        } catch {}
      }
    }
  })

  return (
    <ReferralStatisticsWrapper>
      <StatisticsTableContaner>
        {isLoading ? (
          <Preloader />
        ) : isValidArray(statistics?.content) ? (
          <>
            <DataTable.Table scale2={`${breakpoints.xl}px`} skipHover>
              <DataTable.Head>
                <DataTable.Row>
                  <DataTable.HeadCell>
                    {tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.EMAIL', 'Email')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell>
                    {tr(
                      'FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.COMMISSION_LAST_MONTH',
                      'Commission Earned Last Month'
                    )}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell>
                    {tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.TOTAL_COMMISSION', 'Total Commission Earned')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell>
                    {tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.STATUS', 'Onboarding Status')}
                  </DataTable.HeadCell>
                  <DataTable.HeadCell>
                    {tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.FEE', 'Application Fee')}
                  </DataTable.HeadCell>
                </DataTable.Row>
              </DataTable.Head>
              <DataTable.Body>
                {statistics.content.map(
                  (
                    { isApplicationFeePaid, lastMonthTransactions, onboardingStatus, progenyEmail, totalCommission },
                    index
                  ) => (
                    <DataTable.Row data-test='table-row' key={index}>
                      <DataTable.Cell
                        className='no-border'
                        caption={tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.EMAIL', 'Email')}
                        data-test='email'>
                        {progenyEmail}
                      </DataTable.Cell>
                      <DataTable.Cell
                        className='no-border'
                        caption={tr(
                          'FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.COMMISSION_LAST_MONTH',
                          'Commission Earned Last Month'
                        )}
                        data-test='commission-last-month'>
                        {formatMoney(lastMonthTransactions, currencyCode, 2)}
                      </DataTable.Cell>
                      <DataTable.Cell
                        className='no-border'
                        caption={tr(
                          'FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.TOTAL_COMMISSION',
                          'Total Commission Earned'
                        )}
                        data-test='total-commission'>
                        {formatMoney(totalCommission, currencyCode, 2)}
                      </DataTable.Cell>
                      <DataTable.Cell
                        caption={tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.STATUS', 'Onboarding Status')}
                        data-test='onboarding-status'
                        className={`status ${onboardingStatus.toLowerCase()} no-border`}>
                        <StatusBanner status={getOnboardingStatusName(onboardingStatus)} />
                      </DataTable.Cell>
                      <DataTable.Cell
                        className='no-border'
                        caption={tr('FRONTEND.REFERRAL.STATISTICS.TABLE_HEAD.FEE', 'Application Fee')}
                        data-test='application-fee'>
                        {isApplicationFeePaid !== undefined ? (
                          <div className='application-fee-wrapper'>
                            {isApplicationFeePaid ? (
                              <>
                                <PaidIcon /> {tr('FRONTEND.REFERRAL.STATISTICS.PAID', 'Paid')}
                              </>
                            ) : (
                              <>
                                <NotPaidIcon /> {tr('FRONTEND.REFERRAL.STATISTICS.NOT_PAID', 'Not Paid')}
                              </>
                            )}
                          </div>
                        ) : (
                          <>{tr('FRONTEND.REFERRAL.STATISTICS.NOT_APPLICABLE', 'Not Applicable')}</>
                        )}
                      </DataTable.Cell>
                    </DataTable.Row>
                  )
                )}
              </DataTable.Body>
            </DataTable.Table>
            <Pagination {...pagination.register()} />
          </>
        ) : error ? (
          <ErrorDisplay error={error} />
        ) : (
          <NoElementsMessage message={tr('FRONTEND.REFERRAL.STATISTICS.NO_REFERALS', 'No referrals yet')} />
        )}
      </StatisticsTableContaner>
    </ReferralStatisticsWrapper>
  )
}

export default ReferralStatistics

const ReferralStatisticsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: -1rem;
  width: 100%;
  .heading-wrapper {
    display: flex;
    flex-flow: column nowrap;
    color: #85a0ad;
    .statistics-heading {
      font-size: 2rem;
      font-weight: 600;
    }
    .subtitle {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
  .statistics-tooltips {
    display: flex;
    justify-content: space-between;
    @media (max-width: 480px) {
      padding-top: 2rem;
    }
  }
  .csv-export-button-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 480px) {
      justify-content: center;
    }
  }
`
const StatisticsTableContaner = styled.div`
  .err-msg-wrapper {
    justify-content: center;
  }
  .data-table-head {
    .data-table-row {
      .data-table-head-cell {
        vertical-align: middle;
        color: rgba(143, 150, 163, 1);
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }

  .data-table-body {
    .data-table-row {
      border-bottom: 1px solid #f4f6f6;

      .data-table-cell {
        color: #131e3d;
        font-family: inherit;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: normal;
        line-height: 1.7rem;
        &.status {
          font-weight: 600;
        }
        &.approved,
        &.onboarded {
          color: rgba(52, 165, 24, 1);
        }
        &.in-progress {
          color: rgba(228, 196, 30, 1);
        }
        &.rejected {
          color: rgba(255, 49, 49, 1);
        }
        &.not-started {
          color: #85a0ad;
        }
        .application-fee-wrapper {
          display: grid;
          grid-template-columns: 0.2fr 1fr;
          grid-gap: 0.5rem;
          align-items: center;
        }
      }
      .data-table-cell-caption {
        text-transform: none;
      }
    }
  }

  .data-table-cell,
  .data-table-head-cell {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: ${breakpoints.xl}px) {
    .data-table-container {
      background: transparent;
      border-radius: 8px;
    }

    .data-table {
      border-radius: 8px;

      .data-table-head {
        .data-table-row {
          .data-table-head-cell {
            :first-of-type {
              padding-left: 3rem;
              border-radius: 8px 0 0 0;
            }
            :last-of-type {
              border-radius: 0 8px 0 0;
            }
          }
        }
      }
    }

    .data-table-body {
      .data-table-row {
        .data-table-cell {
          height: 5rem;
          &:first-of-type {
            padding-left: 3rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }

        :last-of-type {
          .data-table-cell {
            :first-of-type {
              border-radius: 0 0 0 8px;
            }
            :last-of-type {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }
  }
  ${tablePadding}
`
