import { ReactNode } from 'react'

import useHideHeaders from '../../hooks/useHideHeaders'

import { tr } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  selectMerchantCapabilities,
  useAppSelector,
  useAuth
} from 'mmfintech-backend-api'

import { NavLink } from 'react-router-dom'
import { SidebarContainer, SideBarContent } from './SideBar.styled'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import CardsIcon from '../../images/icons/SidebarIcons/card-menu-icon.svg?react'
import SavingsIcon from '../../images/icons/SidebarIcons/savings.svg?react'
import VoucherIcon from '../../images/icons/SidebarIcons/vouchers.svg?react'
import AccountsIcon from '../../images/icons/SidebarIcons/accounts.svg?react'
import ExpensesIcon from '../../images/icons/SidebarIcons/expenses.svg?react'
import InvoicesIcon from '../../images/icons/SidebarIcons/invoices.svg?react'
import OverviewIcon from '../../images/icons/SidebarIcons/overview.svg?react'
import TransactionsIcon from '../../images/icons/SidebarIcons/transactions.svg?react'
import InviteAndEarnIcon from '../../images/icons/SidebarIcons/invite-and-earn.svg?react'

const SideBar = () => {
  const userStatus = useAuth()
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const visible = useHideHeaders()
  const customerRole = useAppSelector(selectCurrentUserRole)
  const capabilities = useAppSelector(selectMerchantCapabilities)

  // const { data: merchant } = useAppSelector(selectMerchant)
  // const { capabilities } = merchant || {}

  return (
    <SidebarContainer isOpen visible={visible}>
      {isLoggedIn() && (
        <SideBarContent>
          <NavigationLink
            to={paths.dashboard()}
            label={tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
            icon={<OverviewIcon />}
          />
          <NavigationLink
            to={paths.banking.accounts.list()}
            label={tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
            icon={<AccountsIcon />}
          />
          {capabilities?.enableCardIssuing && (
            <NavigationLink to={paths.cards.list()} label={tr('FRONTEND.HEADER.CARDS', 'Cards')} icon={<CardsIcon />} />
          )}
          <NavigationLink
            to={paths.banking.transactions.list()}
            label={tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
            icon={<TransactionsIcon />}
          />
          <NavigationLink
            to={paths.banking.expenses()}
            label={tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
            icon={<ExpensesIcon />}
          />
          <NavigationLink
            to={paths.invoices.list()}
            label={tr('FRONTEND.HEADER.INVOICES', 'Invoices')}
            icon={<InvoicesIcon />}
          />
          {isOwnerOrAdministrator(customerRole) && capabilities?.allowReferrals && (
            <NavigationLink
              to={paths.referralProgram()}
              label={tr('FRONTEND.HEADER.REFERRAL', 'Invite & Earn')}
              icon={<InviteAndEarnIcon />}
            />
          )}
          {isOwnerOrAdministrator(customerRole) && (
            <NavigationLink
              to={paths.more.savings()}
              label={tr('FRONTEND.HEADER.SAVINGS', 'Savings')}
              icon={<SavingsIcon />}
            />
          )}
          {isOwnerOrAdministrator(customerRole) && capabilities?.enableCashVouchers && (
            <NavigationLink
              to={paths.more.cashVoucher('buy')}
              label={tr('FRONTEND.HEADER.JETON_CASH', 'Jeton cash')}
              icon={<VoucherIcon />}
            />
          )}
        </SideBarContent>
      )}
    </SidebarContainer>
  )
}

export default SideBar

interface NavigationLinkProps {
  to: string
  label: string
  icon: ReactNode
  onClick?: () => void
}

const NavigationLink = ({ to, label, icon, onClick }: NavigationLinkProps) => (
  <NavLink to={to} onClick={onClick} className='sidebar-link'>
    <div>{icon}</div>
    <span className='sidebar-link-text'>{label}</span>
  </NavLink>
)
